import React, { useCallback, useRef, useEffect, useState, useContext } from "react";
import { NotificationManager } from "react-notifications";
import {
  FormProvider,
  useForm,
  useWatch,
  Control,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { LoadingOrange } from "../../../components/Loading";
import SaveBottom from "../../../components/SaveBottom";
import { TextInput, TextArea } from "atoms";
import AnamnesisService from "../../../services/AnamnesisService";
//import "./styles.scoped.scss";
import { NursingTitle, HR } from "../../../components/new/Title";
import { Section } from "./styles";
import _ from "lodash";

interface MethodsProps {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

const Cabecalho: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <NursingTitle>Cabeçalho</NursingTitle>
      <HR />
      <Row>
        <Col>
          <TextInput name="consultationDate" label="Data da Consulta" type="date" />
        </Col>
      </Row>
    </Section>
  );
};


const Observations: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <NursingTitle>Observações</NursingTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
        <div className='col-span-8'>
          <TextArea
            name="internalObservations"
            label="Observações internas"
            placeholder="Digite aqui"
          />
        </div>
        <div className='col-span-8'>
          <TextArea
            name="hauxObservations"
            label="Observações para a equipe Haux"
          />
        </div>
      </div>
    </Section>
  );
};

interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  anamnesisId?: string;
  meetingNumber?: string;
}

const Anamnesis: React.FC = () => {
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { reset, control, handleSubmit, formState } = methods;
  const data = useWatch({ control });
  const finished = useWatch({ control, name: "finish" });
  const history = useHistory();
  const { clientId, anamnesisId, meetingNumber } = useParams<ClientParams>();

  const hasTemplate: boolean = data && data.questionSections && data.questionSections.length > 0;

  const getAnamnesisData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await AnamnesisService.getAllAnamnesis("nursing", clientId);
      const item = _.find(_.get(response, ["data", "data", "items"], []), [
        "_id",
        anamnesisId,
      ]);
      if (item) {
        reset({ ...item, client: clientId });
      } else {
        throw new Error();
      }
    } catch (e) {
      NotificationManager.error("Erro ao obter dados da anamnese");
      history.push(`/client/${clientId}/nursing`);
    }
    setLoading(false);
  }, [clientId, anamnesisId, reset, setLoading, history]);

  useEffect(() => {
    getAnamnesisData();
  }, [getAnamnesisData]);

  const getDataTransform = (data, finished = false) => {
    const form = data;

    if (finished) {
      form.finish = true;
    }

    return form;
  };

  const onSave = useCallback(
    async (data, published = false) => {
      setSaving(true);
      try {
        const values = getDataTransform(data, published);
        await AnamnesisService.insertAnamnesis("nursing", values);
        NotificationManager.success(
          `Ficha ${published ? "salva" : "publicada"} com sucesso`
        );
      } catch (e) {
        NotificationManager.error("Erro ao salvar ficha");
      }
      setSaving(false);
    },
    [setSaving]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await onSave(data, true);
        getAnamnesisData();
      } catch (errors) {
        //@ts-ignore
        console.log(errors.inner.reduce(
          (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? "validation",
              message: currentError.message
            }
          }),
          {}
        )
        );
        NotificationManager.error(
          "Preencha todos os campos obrigatórios para publicar"
        );
      }
    },
    [onSave, getAnamnesisData]
  );

  if (loading) {
    return <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <LoadingOrange style={{ height: 100 }} />
    </div>;
  }

  return (
    <FormProvider {...methods}>
      <form style={{ paddingBottom: 50 }} onSubmit={handleSubmit(onSubmit)}>
        {anamnesisId && (
          <>
            <Row style={{ marginRight: 0 }}>
              <Col>
                <Cabecalho {...methods} />
                <Observations {...methods} />
              </Col>
            </Row>
          </>
        )}
        <SaveBottom
          saving={saving}
          touched={formState.isDirty || !finished}
          showPublish={!finished}
          color={"tech"}
          onSave={() => {
            onSave(data, data.finish);
          }}
          published={finished}
        />
      </form>
    </FormProvider>
  );
};

export default Anamnesis;
