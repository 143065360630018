import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
} from "victory";
import { MonthPickerButton as MonthPicker} from "@/components/ui/monthpicker";
import { Button } from "@/components/ui/button"
import { useParams } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement }  from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import ChallengeService from "../../services/ChallengeService";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { format, isAfter, parse, subMonths } from "date-fns"
import { ptBR as locale } from "date-fns/locale"
import { ptBR } from "react-date-range/dist/locale";
import Food from "../../components/Food";
import UsedMedicines from "../../components/UsedMedicines";
import MadeChallenges from "../../components/MadeChallenges";
import Training from "../../components/Training";
import { LoadingPurple } from "../../components/Loading";
import Infos from "../../components/new/Infos";
import { PrimaryLabel } from "../../components/new/Title";
import {
  Content,
  ContentItem,
  ContentIcon,
  StykuItem,
  StykuLeftImage,
  Subtitle,
  TimelineItem,
  TimelineIcon,
  Accordion,
  AccordionHeader,
  AccordionMinus,
  AccordionPlus,
  Weight,
  IMC,
  Fat,
  Abdominal,
  HandGrip,
  StressLevel,
} from "./styles";
import IcWeightSVG from "../../assets/icons/ic-weight.svg";
import IcCircunferenceSVG from "../../assets/icons/ic-circunference.svg";
import IcCalorieSVG from "../../assets/icons/ic-calorie.svg";
import IcClockSVG from "../../assets/icons/ic-clock.svg";
import MealsService from "../../services/MealsService";
import AnamnesisService from "../../services/AnamnesisService";
import ReportService from "../../services/ReportsService";
import TrainingService from "../../services/TrainingService";
import ClientContext from "../../context/ClientContext";
import _ from "lodash";
import debounce from 'lodash/debounce';
import SystemContext from "../../context/SystemContext";
import moment from "moment";
import UsedExams from "../../components/UsedExams";
import { Col, Row } from "reactstrap";
import { H1 } from "../../components";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { toPng } from 'html-to-image';
import ResumePDFDocument from "./ResumePDFDocument";
import Observations from "./Observations";

interface IData {
  date: string;
  value: number;
}
interface IBarChat {
  title: string;
  renderIcon: Function;
  data: IData[];
}
const BarChart: React.FC<IBarChat> = ({ title, renderIcon, data }) => {

  return (
    <Accordion>
      <AccordionHeader>
        <AccordionHeader>
          {renderIcon()}
          <h1>{title}</h1>
        </AccordionHeader>
      </AccordionHeader>
      <VictoryChart
          // adding the material theme provided with Victory
          theme={VictoryTheme.material}
          domainPadding={30}
        >
          <VictoryAxis
            tickValues={[1, 2, 3, 4, 5, 6]}
            style={{
              tickLabels: {
                fontFamily: "var(--font-heavy)",
                fill: "var(--primary-color)",
                fontSize: 10,
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: {
                fontFamily: "var(--font-heavy)",
                fill: "var(--primary-color)",
                fontSize: 10,
              },
            }}
          />
          <VictoryBar
            data={data}
            x="date"
            y="value"
            barWidth={30}
            style={{
              data: { fill: "var(--primary-color)" },
              labels: { fill: "white", fontFamily: "var(--font-heavy)" },
            }}
            labels={({ data, index }) => data[index].value}
            labelComponent={<VictoryLabel dy={25} />}
          />
        </VictoryChart>
    </Accordion>
  );
};

interface ITimelineData {
  type: string;
  name?: string;
  createdAt: string;
  consultationDate: string;
  observations?: string;
  hauxApproach?: string;
  hauxDiagnoses?: string;
  otherObservations?: {
    diagnoses?: string;
  };
  responsibleProfessional?: {
    name: string;
  };
}
interface ITimeline {
  data: ITimelineData[];
}
const Timeline: React.FC<ITimeline> = ({ data }) => {
  const orderData = data.sort(function(a, b) {
        //@ts-ignore
        return b.valueOf - a.valueOf;
      });
  const getTitle = (item: ITimelineData): string => {
    if (item.responsibleProfessional?.name == "João Pedro Corso de Oliveira") {
      console.log("TIMELINE", _.get(item, ['otherObservations', 'diagnoses']))
    }
    if (item.type === "round") {
      return "Round de Equipe"
    }

    return `Realizou uma consulta ${((item.name ?? item.type) || "").toUpperCase()}${(item.responsibleProfessional ? ' com ' + item.responsibleProfessional.name : '')}`
  }

  return (
    <Infos title="Timeline">
      <div
        style={{
          display: "flex",
          width: "100%",
          position: "relative",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <VerticalTimeline>
          {orderData.map((item) => (
            <>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={item.consultationDate}
                contentArrowStyle={{ borderRight: "7px solid", color: `var(--${item.type})` }}
                icon={<TimelineIcon anamnesis={item.type} style={{ flex: "none" }} />}
                iconStyle={{ padding: 10 }}
              >
                <TimelineItem key={item.createdAt}>
                  <div>
                    <h1
                      style={{ color: `var(--${item.type})` }}
                    >{getTitle(item)}</h1>
                    {!item.observations && !item.hauxApproach && !item.hauxDiagnoses && !item.otherObservations && !item.hauxObservations &&  (
                      <span>Não houveram observações nessa entrada.</span>
                    )}
                    {item.hauxObservations && (
                      <div
                        style={{ width: 180, display: "block", overflowWrap: "break-word" }}
                        dangerouslySetInnerHTML={{ __html: item.hauxObservations }}
                      />
                    )}
                     {item.observations && (
                      <div
                        style={{ width: 180, display: "block", overflowWrap: "break-word" }}
                        dangerouslySetInnerHTML={{ __html: item.observations }}
                      />
                    )}
                    {_.get(item, ['otherObservations', 'diagnoses']) && (
                      <>
                        <br />
                        <div
                          style={{ width: 180, display: "block", overflowWrap: "break-word" }}
                          dangerouslySetInnerHTML={{ __html: _.get(item, ['otherObservations', 'diagnoses']) }}
                        />
                        <br />
                      </>
                    )}
                    {item.hauxDiagnoses && (
                      <>
                        <br />
                        <div
                          style={{ width: 180, display: "block", overflowWrap: "break-word" }}
                          dangerouslySetInnerHTML={{ __html: item.hauxDiagnoses }}
                        />
                        <br />
                      </>
                    )}
                    {item.hauxApproach && (
                      <>
                        <br />
                        <div
                          style={{ width: 180, display: "block", overflowWrap: "break-word" }}
                          dangerouslySetInnerHTML={{ __html: item.hauxApproach }}
                        />
                        <br />
                      </>
                    )}
                  </div>
                </TimelineItem>
              </VerticalTimelineElement>
            </>
          ))}
        </VerticalTimeline>
      </div>
    </Infos>
  );
};

interface IExerciseApp {
  id: string;
  title: string;
  description?: string;
  minutes: number;
  when: string;
}

const Trainings: React.FC<{ onChange?: Function}> = ({ onChange }) => {
  const { client } = useContext(ClientContext);
  const [loading, setLoading] = useState(false);
  const [exerciseApps, setExerciseApps] = useState<IExerciseApp[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateRange<Date>>(
    [moment().add(-1, "year").toDate(),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    ]);

  const getTrainingsData = useCallback(async () => {
    if (client) {
      setLoading(true);
      try {
        const responseModels = await TrainingService.getAllModelTrainings();
        const models = _.get(responseModels, ["data", "data", "items"]).reverse();
        const responseTraining = await TrainingService.getResume(
          client._id,
          moment(selectedDate[0]).format("YYYY-MM-DD"),
          moment(selectedDate[1]).format("YYYY-MM-DD")
        );

        var { exercises, trainings } = _.get(responseTraining, ["data", "data"], []);
        var newExerciseApps: IExerciseApp[] = [];
        exercises.forEach(e => {
          const eDoc = _.get(e, ['exercise_doc'], {})
          newExerciseApps.push({
            id: e._id,
            title: eDoc.title,
            minutes: e.time,
            description: eDoc.category,
            when: e.when,
          })
        });
        trainings.forEach((training) => {
          const model = _.find(models, (m: any) => m._id === training.training);
          training.model = model;
          newExerciseApps.push({
            id: training._id,
            title: model ? model.title : "Treino At Home",
            minutes: training.time,
            description: _.get(training, ['training_doc', 'exercises'], []).reduce((str, e) => {
              const eDoc = _.get(e, ['exercise_doc'], {})
              let strAppend = str.length == 0 ? str : `${str}\n`
              return `${strAppend}Exercício: ${eDoc.title || '-'} , ${e.series} séries, ${e.repetitions} repetições`
            }, ''),
            when: training.when,
          })
        });
        newExerciseApps = _.sortBy(newExerciseApps, 'when').reverse()
        setExerciseApps(newExerciseApps);
      } catch (e) {}
      setLoading(false);
    }
  }, [selectedDate, client, setExerciseApps, setLoading]);

  useEffect(() => {
    getTrainingsData();
  }, [getTrainingsData]);

  useEffect(() => {
    onChange?.(exerciseApps)
  }, [exerciseApps])

  return (
    <Infos
      title="Treinos realizados pelo paciente"
      right={() => (
        <div style={{ position: "relative" }}>
          <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="De"
              endText="Até"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              inputFormat="dd/MM/yyyy"
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }} />
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
        </div>
      )}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading && <LoadingPurple style={{ height: 100 }} />}
        {!loading && (!exerciseApps || Object.keys(exerciseApps).length === 0) && (
          <p style={{ marginTop: 20 }}>
            Não foram encontrados dados no período selecionado
          </p>
        )}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {exerciseApps.map((exercise: any) => (
          <Training
            key={exercise.id}
            name={exercise.title}
            minutes={exercise.minutes}
            description={exercise.description}
            when={moment(exercise.when).format('DD/MM/YYYY')}
          />
        ))}
      </div>
    </Infos>
  );
};

const Challenges: React.FC = () => {
  const { client } = useContext(ClientContext);
  const { clientId } = useParams<ClientParams>();
  const [loading, setLoading] = useState(false);
  const [madeChallenges, setMadeChallenges] = useState([]);
  const [selectedDate, setSelectedDate] = useState<DateRange<Date>>(
    [new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    ]);

  const sortedMadeChallenges = madeChallenges.sort((a, b) => {
    return - (
      //@ts-ignore
      moment(a.challenge.createdAt).valueOf()
      //@ts-ignore
      - moment(b.challenge.createdAt).valueOf()
    );
  });

  const getMadeChallenges = useCallback(async () => {
    if (client) {
      setLoading(true);
      try {
        const response = await ChallengeService.getHistory(clientId);
        const made = _.get(response, ["data", "data"], []).filter(
          //@ts-ignore
          (a: any) => new Date(a.challenge.createdAt).getTime() >= selectedDate[0].getTime()
            //@ts-ignore
            && new Date(a.challenge.createdAt).getTime() <= selectedDate[1].getTime()
        );
        setMadeChallenges(made);
      } catch (e) {
        setMadeChallenges([]);
      }
      setLoading(false);
    }
  }, [client, selectedDate, clientId, setMadeChallenges]);

  useEffect(() => {
    getMadeChallenges();
  }, [getMadeChallenges]);

  return (
    <Infos
      title="Desafios Realizados pelo paciente"
      right={() => (
        <div style={{ position: "relative" }}>
          <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="De"
              endText="Até"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              inputFormat="dd/MM/yyyy"
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }} />
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
        </div>
      )}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading && <LoadingPurple style={{ height: 100 }} />}
        {!loading && (!madeChallenges || madeChallenges.length === 0) && (
          <p style={{ marginTop: 20 }}>
            Não foram encontrados dados no período selecionado
          </p>
        )}
      </div>
      <Row>
        <Col>
          {sortedMadeChallenges.length > 0 && (
            sortedMadeChallenges.map((item: any) => (
              <MadeChallenges
                name={`${item.challenge.name} - ${item.challenge.translation}`}
                when={moment(item.challenge.createdAt).format("DD/MM/YYYY HH:mm")}
              />
            ))) 
          }
        </Col>
      </Row>
    </Infos>
  );
};
interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  anamnesisId?: string;
}

const Resume: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date())
  const [techs, setTechs] = useState([]);
  const [exerciseApps, setExerciseApps] = useState([]);
  const [currentAnamnesis, setCurrentAnamnesis] = useState([]);
  const [physicalExams, setPhysicalExams] = useState({});
  const [complementaryExams, setComplementaryExams] = useState({});
  const [preventiveExams, setPreventiveExams] = useState({});
  const { clientId } = useParams<ClientParams>();
  const { goal, client } = useContext(ClientContext);
  const [graphs, setGraphs] = useState<String[]>([]);
  const resumeRef = useRef();
  const weightRef = useRef();
  const imcRef = useRef();
  const fatPercentageRef = useRef();
  const waistAbdominalRef = useRef();
  const muscleMassRef = useRef();
  const stressLevelRef = useRef();
  const mutationObserverRef = useRef();
  const resizeObserverRef = useRef();

  const updateGraphs = useCallback(() => {
    let graphsRef = [
        resumeRef.current,
        weightRef.current, imcRef.current,
        fatPercentageRef.current, waistAbdominalRef.current,
        muscleMassRef.current, stressLevelRef.current]

      function getBase64(ref) {
          return toPng(ref).then(imgBase64 => {
              return imgBase64;
          }).catch(error => {
              return null
          });
      }

      async function processPromises(promises) {
        try {
            const results = await Promise.all(promises);
            return results;
        } catch (error) {
            return []
        }
    }

      let graphPromises = []
      graphsRef.forEach((ref) => {
        let graph = getBase64(ref)
        if (graph != null) {
          //@ts-ignore
          graphPromises.push(graph)
        }
      })

      processPromises(graphPromises).then(results => {
          setGraphs(results)
          setLoading(true)
          setTimeout(() => setLoading(false), 500)
      })
  }, [graphs, setGraphs, setLoading])

  const debouncedUpdateGraphs = useCallback(debounce(updateGraphs, 1000), []);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const techResponse = await AnamnesisService.getAllAnamnesis(
        "tech",
        clientId
      );
      const nutriResponse = await AnamnesisService.getAllAnamnesis(
        "nutri",
        clientId
      );
      const medResponse = await AnamnesisService.getAllAnamnesis(
        "med",
        clientId
      );
      const psicoResponse = await AnamnesisService.getAllAnamnesis(
        "psico",
        clientId
      );
      const physicalResponse = await AnamnesisService.getAllAnamnesis(
        "physical",
        clientId
      );
      const nursingResponse = await AnamnesisService.getAllAnamnesis(
        "nursing",
        clientId
      );
      const reportResponse = await ReportService.getAll(
        clientId,
        "round"
      );

      let reports: { _id: string; }[] = _.get(reportResponse, ["data", "data", "items"], [])
      var reportItems: any[] = []
      
      for (let i = 0; i < reports.length; i++) {
        let report = reports[i];
        const reportItemsResponse = await ReportService.getItems(report._id)
        reportItems = reportItems.concat(_.get(reportItemsResponse, ["data", "data", "items"], []))
      }

      var newItems = [];
      //@ts-ignore
      newItems = [
        ...newItems,
        ..._.get(techResponse, ["data", "data", "items"], []).map((a) => ({
          ...a,
          type: "tech",
          valueOf: moment(a.consultationDate, "DD/MM/YYYY").valueOf(),
        })),
      ];
      //@ts-ignore
      newItems = [
        ...newItems,
        ..._.get(nutriResponse, ["data", "data", "items"], []).map((a) => ({
          ...a,
          type: "nutri",
          valueOf: moment(a.consultationDate, "DD/MM/YYYY").valueOf(),
        })),
      ];
      //@ts-ignore
      newItems = [
        ...newItems,
        ..._.get(nursingResponse, ["data", "data", "items"], []).map((a) => ({
          ...a,
          type: "nursing",
          valueOf: moment(a.consultationDate, "DD/MM/YYYY").valueOf(),
        })),
      ];
      //@ts-ignore
      newItems = [
        ...newItems,
        ..._.get(medResponse, ["data", "data", "items"], []).map((a) => ({
          ...a,
          type: "med",
          valueOf: moment(a.consultationDate, "DD/MM/YYYY").valueOf(),
        })),
      ];
      //@ts-ignore
      newItems = [
        ...newItems,
        ..._.get(psicoResponse, ["data", "data", "items"], []).map((a) => ({
          ...a,
          ...a.anamnesi,
          type: "psico",
          valueOf: moment(a.consultationDate, "DD/MM/YYYY").valueOf(),
        })),
      ];

      //@ts-ignore
      newItems = [
        ...newItems,
        ..._.get(physicalResponse, ["data", "data", "items"], []).map((a) => ({
          ...a,
          type: "physical",
          name: "no Educador Físico",
          valueOf: moment(a.consultationDate, "DD/MM/YYYY").valueOf(),
        })),
      ];
      //@ts-ignore
      newItems = [
        ...newItems,
        ...reportItems.map((a) => ({
          ...a,
          type: "round",
          observations: a.text,
          consultationDate: moment(a.createdAt).format("DD/MM/YYYY"),
          valueOf: moment(a.createdAt).valueOf(),
        })),
      ];
      newItems = newItems.sort(function (a, b) {
        //@ts-ignore
        return (a.valueOf - b.valueOf);
      });

      //@ts-ignore
      setData(newItems.filter((item) => item.finish));
      //@ts-ignore
      setCurrentAnamnesis(newItems.filter((item) => item.type === "med" && item.finish));
      //@ts-ignore
      setTechs(newItems.filter((item) => item.type === "tech" && item.finish));
    } catch (e) {}
    setLoading(false);
  }, [clientId, setLoading, setData, setTechs, setCurrentAnamnesis]);


  const getMonthData = useCallback(() => {
    let exercises = {} 
    let images = []

    exerciseApps.slice(0, 30).reverse().forEach((e) => {

      //@ts-ignore
      let day = format(new Date(e.when), "dd 'de' MMMM 'de' yyyy", { locale })
      if (!exercises[day]) {
        exercises[day] = []
      }
      //@ts-ignore
      exercises[day].push({ exercise: e.title, minutes: e.minutes })
    })

    let measures: [] = [];

    try {

      //@ts-ignore
      let current = format(currentDate, "MMM/yyyy", { locale })
      let last12MonthDate = subMonths(currentDate, 12)
      let observations = data.filter((a) => {

        //@ts-ignore
        let consultationDate = parse(a.consultationDate, "dd/MM/yyyy", new Date())

        //@ts-ignore
        if (a.type === "tech") {
          const date = format(consultationDate, "dd 'de' MMMM 'de' yyyy", { locale })
          var weight = _.get(a, ["bodyComposition", "weight"])
          weight = weight ? weight.toFixed(1).replace(".",",") + "Kg" : undefined
          var imc = _.get(a, ["bodyComposition", "imc"])
          imc = imc ? imc.toFixed(1).replace(".",",") : undefined
          var bodyFatPercentage = _.get(a, ["bodyComposition", "bodyFatPercentage"])
          bodyFatPercentage = bodyFatPercentage ? bodyFatPercentage.toFixed(1).replace(".",",") + "%" : undefined
          var waistAbdominal = _.get(a, ["bodyComposition", "waistAbdominal"])
          waistAbdominal = waistAbdominal ? waistAbdominal.toFixed(1).replace(".",",") + "cm" : undefined
          var handGrip = _.get(a, ["bodyComposition", "handGrip"])
          handGrip = handGrip ? handGrip.toFixed(1).replace(".",",") + "Kg" : undefined
          var muscleMass = _.get(a, ["bodyComposition", "muscleMass"])
          muscleMass = muscleMass ? muscleMass.toFixed(1).replace(".",",") + "Kg" : undefined
          let image = _.get(a, ["stykuMeasure", "body_left"])

          if (image) {
            //@ts-ignore
            images.push({ image, date })
          }

          //@ts-ignore
          measures.push({ weight, imc, bodyFatPercentage, waistAbdominal, handGrip, muscleMass, date })
        }

        return isAfter(consultationDate, last12MonthDate)
      }).map((a) => {
        let consultationDate = _.get (a, ["consultationDate"])
        let observations = _.get (a, ["observations"]) ?? "Não houveram observações nessa entrada."
        let responsibleProfessional = _.get (a, ["responsibleProfessional"])
        let title = `CONSULTA ${_.get(a, ["type"])} - ${consultationDate}:`.toUpperCase()
        if (responsibleProfessional) {
          title = `${_.get(responsibleProfessional, ["name"])} - ${_.get(responsibleProfessional, ["register"])} - ${consultationDate}:`.toUpperCase()
        }

        return { title, observations }
      })

      return { exercises, observations, measures, images }
    } catch {}

    return { exercises, measures, images, observations: []}
  }, [data, exerciseApps, currentDate]);

  useEffect(() => {
    let graphsRef = [
    resumeRef.current,
    weightRef.current, imcRef.current,
    fatPercentageRef.current, waistAbdominalRef.current,
    muscleMassRef.current, stressLevelRef.current]

    if (data.length == 0 || graphs.length == graphsRef.length) {
      //@ts-ignore
      mutationObserverRef.current?.disconnect()
      //@ts-ignore
      resizeObserverRef.current?.disconnect()
      return
    }

    if (!mutationObserverRef.current) {
      //@ts-ignore
      mutationObserverRef.current = new MutationObserver(() => {
        debouncedUpdateGraphs()
      });
    } else {
      //@ts-ignore
      mutationObserverRef.current?.disconnect()
    }

    if (!resizeObserverRef.current) {
      //@ts-ignore
      resizeObserverRef.current = new ResizeObserver(() => {
        debouncedUpdateGraphs()
      });
    } else {
      //@ts-ignore
      resizeObserverRef.current?.disconnect()
    }
    
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    };

    graphsRef.forEach((ref) => {
      //@ts-ignore
      mutationObserverRef.current.observe(ref, config);
      //@ts-ignore
      resizeObserverRef.current.observe(ref)
    })

    debouncedUpdateGraphs()
  }, [data, graphs, setLoading])

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (currentAnamnesis.length > 0) {
      setPhysicalExams(
        //@ts-ignore
        currentAnamnesis[currentAnamnesis.length - 1].physicalExams
      );
    }
  }, [currentAnamnesis]);

  useEffect(() => {
    if (currentAnamnesis.length > 0) {
      setComplementaryExams(
        //@ts-ignore
        currentAnamnesis[currentAnamnesis.length - 1].exams
      );
    }
  }, [currentAnamnesis]);

  useEffect(() => {
    if (currentAnamnesis.length > 0) {
      setPreventiveExams(
        //@ts-ignore
        currentAnamnesis[currentAnamnesis.length - 1].preventiveExams
      );
    }
  }, [currentAnamnesis]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingPurple style={{ height: 100 }} />
      </div>
    );
  }

  let monthData = getMonthData()

  return (
    <div>
      <div className="flex mb-2 gap-4">
        <MonthPicker
          currentMonth={currentDate}
          onMonthChange={setCurrentDate}
        />
    
        <PDFDownloadLink
              document={<ResumePDFDocument name={client.name}
              period={format(currentDate, "MMM/yyyy", { locale })}
              //@ts-ignore
              exercises={monthData.exercises} observations={monthData.observations}
              //@ts-ignore
              measures={monthData.measures} images={monthData.images} graphs={graphs}
            />}
            fileName={`Resumo ${client.name}.pdf`}>
              {({ loading }) => (
                <Button type="button" disabled={loading || graphs.length == 0}>
                  {loading || graphs.length == 0 ? "Preparando documento..." : "Baixar Resumo"}
                  {(loading || graphs.length == 0) && <LoadingPurple style={{ height: 50 }} />}
                </Button>
              )}
          </PDFDownloadLink>

        
        
      </div>
      {monthData.observations.length === 0 && <div className="mb-4">Não houveram observações no mês selecionado</div>}
      <Infos title="Estatísticas">
        <Content>
          {techs.map((item: any) => (
            <StykuItem key={item._id}>
              <StykuLeftImage src={(item.stykuMeasure || {}).body_left} />
              <small>
                {moment(item.consultationDate, "DD/MM/YYYY").format("DD")}
              </small>
              <p>{moment(item.consultationDate, "DD/MM/YYYY").format("MMM")}</p>
            </StykuItem>
          ))}
        </Content>
        <div
          style={{ width: '100%' }}
          //@ts-ignore
          ref={resumeRef}>
          <Content style={{ justifyContent: "space-between", marginTop: 16 }}>
            <ContentItem style={{ flex: 1 }}>
              <PrimaryLabel>Peso atual</PrimaryLabel>
              <div>
                <p>
                  {_.get(_.last(techs), ["bodyComposition", "weight"], 0)}
                  <small>KG</small>
                </p>
                <ContentIcon src={IcWeightSVG} />
              </div>
            </ContentItem>
            <ContentItem>
              <PrimaryLabel>Circunferência abdominal</PrimaryLabel>
              <div>
                <p>
                  {_.get(_.last(techs), ["bodyComposition", "waistAbdominal"], 0)}
                  <small>CM</small>
                </p>
                <ContentIcon src={IcCircunferenceSVG} />
              </div>
            </ContentItem>
            <ContentItem>
              <PrimaryLabel>Meta calórica diária</PrimaryLabel>
              <div>
                <p>
                  {_.get(goal, ["minCalories"])}-{_.get(goal, ["maxCalories"])}
                  <small>Kcal</small>
                </p>
                <ContentIcon src={IcCalorieSVG} />
              </div>
            </ContentItem>
            <ContentItem>
              <PrimaryLabel>Meta de exercícios diários</PrimaryLabel>
              <div>
                <p>
                  {_.get(_.last(techs), ["weeklyAerobicMinutes"], 0)}
                  <small>minutos</small>
                </p>
                <ContentIcon src={IcClockSVG} />
              </div>
            </ContentItem>
          </Content>
        </div>
        <Content style={{ padding: 0 }}>
          <div
            style={{flex: 1}}
            //@ts-ignore
            ref={weightRef}>
            <BarChart
              title="Peso"
              renderIcon={() => <Weight />}
              data={techs.map((item: any) => ({
                date: moment(item.consultationDate, "DD/MM/YYYY").format(
                  "DD MMM"
                ),
                value: (item.bodyComposition || {}).weight,
              }))}
            />
          </div>
          
          <div
            style={{flex: 1}}
            //@ts-ignore
            ref={imcRef}>
            <BarChart
              title="IMC"
              renderIcon={() => <IMC />}
              data={techs.map((item: any) => ({
                date: moment(item.consultationDate, "DD/MM/YYYY").format(
                  "DD MMM"
                ),
                value: Math.round((item.bodyComposition || {}).imc * 10) / 10,
              }))}
            />
          </div>
        </Content>
        <Content style={{ padding: 0 }}>
          <div
            style={{flex: 1}}
            //@ts-ignore
            ref={fatPercentageRef}>
            <BarChart
              title="Percentual de gordura"
              renderIcon={() => <Fat />}
              data={techs.map((item: any) => ({
                date: moment(item.consultationDate, "DD/MM/YYYY").format(
                  "DD MMM"
                ),
                value: (item.bodyComposition || {}).bodyFatPercentage,
              }))}
            />
          </div>
          <div
            style={{flex: 1}}
            //@ts-ignore
            ref={waistAbdominalRef}>
            <BarChart
              title="Circunferência abdominal"
              renderIcon={() => <Abdominal />}
              data={techs.map((item: any) => ({
                date: moment(item.consultationDate, "DD/MM/YYYY").format(
                  "DD MMM"
                ),
                value:
                  Math.round((item.bodyComposition || {}).waistAbdominal * 10) /
                  10,
              }))}
            />
          </div>
        </Content>
        <Content style={{ padding: 0 }}>
          <div
            style={{flex: 1}}
            //@ts-ignore
            ref={muscleMassRef}>
            <BarChart
              title="Força muscular"
              renderIcon={() => <HandGrip />}
              data={techs.map((item: any) => ({
                date: moment(item.consultationDate, "DD/MM/YYYY").format(
                  "DD MMM"
                ),
                value: (item.bodyComposition || {}).handGrip,
              }))}
            />
          </div>
          <div
            style={{flex: 1}}
            //@ts-ignore
            ref={stressLevelRef}>
            <BarChart
              title="Nível de estresse"
              renderIcon={() => <StressLevel />}
              data={techs.map((item: any) => ({
                date: moment(item.consultationDate, "DD/MM/YYYY").format(
                  "DD MMM"
                ),
                value: Math.round(item.stressLevel * 10) / 10,
              }))}
            />
          </div>
        </Content>
        <Content style={{ padding: 0, maxWidth: "50%" }}>
          <BarChart
            title="Massa Muscular"
            renderIcon={() => <HandGrip />}
            data={techs.map((item: any) => ({
              date: moment(item.consultationDate, "DD/MM/YYYY").format(
                "DD MMM"
              ),
              value: (item.bodyComposition || {}).muscleMass,
            }))}
          />
        </Content>
      </Infos>
      <Observations clientId={clientId ?? ""} />
      <Timeline data={data} />
      <Trainings onChange={setExerciseApps} />
      <Challenges />
      <Infos title="Medicamentos Utilizados">
        {
          currentAnamnesis.length > 0 ? (
            //@ts-ignore
            currentAnamnesis[currentAnamnesis.length - 1].medicines.map((item) => (
              <div>
                <UsedMedicines
                  name={item.value}
                  observations={item.observation}
                  dosage={item.dosage}
                  status={item.status}
                />
              </div>
            ))
          ) : <></>
        }
      </Infos>
      <Infos title="Exames Utilizados">
        <Row>
          <Col>
            {/*@ts-ignore*/}
            <H1 color="resume">Físicos</H1>
          </Col>
        </Row>
        {
          Object.entries(physicalExams || []).map((item: any) => (
            <div>
              {
                item[1] !== "" ? (
                  <UsedExams
                    name={
                      item[0] === "acanthosisNigricans"
                        ? "Acantose nigricans"
                        : item[0] === "hirsutism"
                          ? "Hirsutismo"
                          : item[0] === "thyroidPalpation"
                            ? "Palpação"
                            : item[0] === "abdominalPalpation"
                              ? "Palpação do abdomem"
                              : item[0] === "lowerMembers"
                                ? "Membros Inferiores"
                                : item[0] === "otherPositiveTestData"
                                  ? "Outros"
                                  : ""
                    }
                    value={item[1]}
                  />
                ) : <></>
              }
            </div>
          ))
        }
        <Row>
          <Col>
            {/*@ts-ignore*/}
            <H1 color="resume">Complementares</H1>
          </Col>
        </Row>
        {
          Object.entries(complementaryExams || []).map((item: any) => (
            <div>
              {
                item[1] !== "" ? (
                  <UsedExams
                    name={
                      item[0] === "escoreCalcio"
                        ? "Escore de Cálcio"
                        : item[0] === "others"
                          ? "Outros"
                          : item[0] === "equ"
                            ? "Exame Qualitativo de Urina (EQU)"
                            : item[0] === "VSG"
                              ? "Velocidade de Sedimentação Globular (VSG)"
                              : item[0] === "ecocardiogramaDate"
                                ? ""
                                : item[0]
                    }
                    value={
                      item[0] === "glicemia"
                        ? `${item[1]} mg/dl`
                        : item[0] === "ecocardiogramaDate"
                          ? ""
                          : item[1]
                    }
                  />
                ) : <></>
              }
            </div>
          ))
        }
        <Row>
          <Col>
            {/*@ts-ignore*/}
            <H1 color="resume">Preventivos</H1>
          </Col>
        </Row>
        {
          //@ts-ignore
          (preventiveExams || {}).have === true ? (
            <div>
              {/*@ts-ignore*/}
              <UsedExams name="Urológicos/Ginecológicos" value={preventiveExams.diagnosis} />
            </div>
          ) : <></>
        }
      </Infos>
    </div>
  );
};

export default Resume;
