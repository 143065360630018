import Swal from "sweetalert2";

interface AlertConfirmProps {
  title?: string;
  subtitle?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

interface AlertProgressProps {
  title: string;
  subtitle?: string;
}

interface AlertErrorProps {
  title?: string;
  subtitle?: string;
}

interface AlertSuccessProps {
  title?: string;
  subtitle?: string;
  didClose?: () => void;
}

export const AlertConfirm = async ({
  title,
  subtitle,
  cancelButtonText,
  confirmButtonText,
}: AlertConfirmProps): Promise<boolean> => {
  const confirmResponse = await Swal.fire({
    title: title ?? "Você tem certeza que deseja excluir?",
    html: subtitle ?? `Essa ação não poderá ser desfeita`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: cancelButtonText ?? "Cancelar",
    confirmButtonText: confirmButtonText ?? "Sim!",
  });
  return confirmResponse.isConfirmed;
};

export const AlertProgress = ({ title, subtitle }: AlertProgressProps) => {
  Swal.fire({
    title: title,
    text: subtitle ?? 'Em progresso...',
    timerProgressBar: true,
    didOpen: () => {
        Swal.showLoading()
    }
  })
}

export const AlertError = ({ title, subtitle }: AlertErrorProps) => {
  Swal.fire({
    title: title ?? "Erro",
    text: subtitle ?? 'Não foi possível concluir',
    timerProgressBar: true,
    icon: 'error',
    didOpen: () => {
        Swal.hideLoading()
    }
  })
}

export const AlertSuccess = ({ title, subtitle, didClose }: AlertSuccessProps) => {
  Swal.fire({
    title: title ?? 'Sucesso',
    text: subtitle ?? 'Salvo com sucesso',
    icon: 'success',
    didClose: didClose
  })
}

export const AlertClose = () => {
  Swal.close();
}
