import React, { useCallback, useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { selectUser } from "redux/ducks/Auth/selectors";
import {
  FormProvider,
  useForm,
  useWatch,
  Control,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useHistory } from "react-router-dom";
import { LoadingPurple } from "../../../components/Loading";
import { NumberInput, CheckGroup, RadioGroup } from "../../../components/3.0";
import { TextInput, TextArea, Select } from "atoms";
import { PhysicalTitle, HR } from "../../../components/new/Title";
import SaveBottom from "../../../components/SaveBottom";
import { Section } from "./styles";
import HEALTH_PROBLEMS from "../../../utils/healthProblems";
import MUSCLE_ACHES from "../../../utils/muscleAches";
import MOVEMENT_DIFFICULTY from "../../../utils/movementDifficulty";
import PHYSICAL_ACTIVITIES from "../../../utils/physicalActivities";
import ClientContext from "../../../context/ClientContext";
import AnamnesisService from "../../../services/AnamnesisService";
import _ from "lodash";
import { IPhysicalChecklist } from "./schema";

interface MethodsProps {
  control: Control<IPhysicalChecklist>;
  setValue: UseFormSetValue<IPhysicalChecklist>;
}

const ConsultationDate: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <PhysicalTitle>Consulta atual</PhysicalTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-12">
        <div className="col-span-8">
          <TextInput
            name="consultationDate"
            label="Data da Consulta"
            type="date"
          />
        </div>
      </div>
    </Section>
  );
};

const CheckList: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <PhysicalTitle>Checklist Educador Físico</PhysicalTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-12">
        <div className="col-span-8">
          <RadioGroup
            name="haveTrainedThisWeek"
            label="O paciente treinou na semana?"
            options={[true, false].map((t) => ({
              label: t ? "Sim" : "Näo",
              value: t,
            }))}
          />
          <TextInput
            name="countOfTrainedThisWeek"
            label="Se sim, quantas vezes?"
          />
        </div>
        <div className="col-span-8">
          <RadioGroup
            name="didAerobicThisWeek"
            label="O paciente fez as atividades aeróbicas combinadas além do treino de força?"
            options={[true, false].map((t) => ({
              label: t ? "Sim" : "Näo",
              value: t,
            }))}
          />
          <TextInput name="whyNotDidAerobicThisWeek" label="Se não, por que?" />
        </div>
        <div className="col-span-8">
          <RadioGroup
            name="haveBeenRegisteringAppActitivy"
            label="O paciente tem feito o registro das atividades no app?"
            options={[true, false].map((t) => ({
              label: t ? "Sim" : "Näo",
              value: t,
            }))}
          />
          <TextInput
            name="whyNotRegisteringAppActitivy"
            label="Se não, por que?"
          />
        </div>
        <div className="col-span-8">
          <RadioGroup
            name="havingDifficulty"
            label="O paciente tem feito o registro das atividades no app?"
            options={[true, false].map((t) => ({
              label: t ? "Sim" : "Näo",
              value: t,
            }))}
          />
          <TextInput name="whyHavingDifficulty" label="Se sim, por que?" />
        </div>
      </div>
    </Section>
  );
};

const Observations: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <PhysicalTitle>Observações</PhysicalTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
        <div className="col-span-8">
          <TextArea name="observations" label="Observações" />
        </div>
        <div className="col-span-8">
          <TextArea
            name="hauxApproach"
            label="Como você gostaria de ser abordado caso a equipe perceba que você não está seguindo o programa?"
          />
        </div>
      </div>
    </Section>
  );
};

interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  anamnesisId?: string;
}

const Anamnesis: React.FC = () => {
  const methods = useForm<IPhysicalChecklist>({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { reset, control, handleSubmit, formState } = methods;
  const data = useWatch({ control });
  const finished = useWatch({ control, name: "finish" });
  const history = useHistory();
  const { clientId, anamnesisId } = useParams<ClientParams>();

  const getAnamnesisData = useCallback(async () => {
    if (anamnesisId) {
      setLoading(true);
      try {
        const response = await AnamnesisService.getById(
          "physicalChecklist",
          anamnesisId
        );
        const item = _.get(response, ["data", "data"]);
        if (item) {
          reset({ ...item, client: clientId });
        } else {
          throw new Error();
        }
      } catch (e) {
        NotificationManager.error("Erro ao obter dados da anamnese");
        history.push(`/client/${clientId}/physical`);
      }
      setLoading(false);
    }
  }, [clientId, anamnesisId, reset, setLoading, history]);

  useEffect(() => {
    getAnamnesisData();
  }, [getAnamnesisData]);

  const onSave = useCallback(
    async (data, published = false) => {
      setSaving(true);
      try {
        const values = { ...data, finish: data.finish || published };
        await AnamnesisService.updateAnamnesis(
          "physicalChecklist",
          anamnesisId,
          values
        );
        NotificationManager.success(
          `Ficha ${published ? "salva" : "publicada"} com sucesso`
        );
      } catch (e) {
        NotificationManager.error("Erro ao salvar ficha");
      }
      setSaving(false);
    },
    [setSaving]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await onSave(data, true);
        getAnamnesisData();
      } catch (errors) {
        //@ts-ignore
        console.log(errors.inner);
        NotificationManager.error(
          "Preencha todos os campos obrigatórios para publicar"
        );
      }
    },
    [onSave, getAnamnesisData]
  );

  const onError = () => {
    NotificationManager.error(
      "Preencha todos os campos obrigatórios para publicar"
    );

    setTimeout(() => {
      //@ts-ignore
      const [firstError]: [HTMLElement] = document.getElementsByClassName(
        "field-error"
      );
      //@ts-ignore
      firstError.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 1000);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingPurple style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ paddingBottom: 50 }}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {anamnesisId && (
          <>
            <ConsultationDate {...methods} />
            <CheckList {...methods} />
            <Observations {...methods} />
          </>
        )}
        <SaveBottom
          saving={saving}
          touched={formState.isDirty || !finished}
          showPublish={!finished}
          color={"physical"}
          onSave={() => {
            onSave(data, data.finish);
          }}
          published={finished}
        />
      </form>
    </FormProvider>
  );
};

export default Anamnesis;
