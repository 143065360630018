import React, { useCallback, useRef, useEffect, useState, useContext } from "react";
import { NotificationManager } from "react-notifications";
import { useDropzone } from "react-dropzone";
import {
  FormProvider,
  useForm,
  useWatch,
  useFieldArray,
  Control,
  FieldValues,
  UseFormSetValue,
  Controller
} from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import { selectUser } from "../../../redux/ducks/Auth/selectors";
import { LoadingOrange } from "../../../components/Loading";
import Collapse from "../../../components/Collapse";
import SaveBottom from "../../../components/SaveBottom";
import { 
  NumberInput,
  CheckGroup,
  RadioGroup,
  Deseases,
  Medicines,
  ExamsRequest,
  Exam,
} from "../../../components/3.0"
import { TextInput, TextArea, Select } from "atoms";
import AnamnesisService from "../../../services/AnamnesisService";
import PDFService from "../../../services/PDFService";
import FileService from "../../../services/FileService";
import { LABORATORY_EXAMS, CARDIOLOGY_EXAMS } from "../../../utils/exams";
//import "./styles.scoped.scss";
import { MedTitle, HR } from "../../../components/new/Title";
import { Section } from "./styles";
import _ from "lodash";
import ClientContext from "../../../context/ClientContext";
import { MedicineForm } from '../../../components/3.0/Medicines';
import MedTemplate from "./template";

interface MethodsProps {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

interface DiagnosisProps extends MethodsProps {
  name: string;
  label: string;
  showYear?: boolean;
  showSymptoms?: boolean;
  showDateLast?: boolean;
  showDoctor?: boolean;
  showDiagnosis?: boolean;
  showObservation?: boolean;
}

const Diagnosis: React.FC<DiagnosisProps> = (props) => {
  const {
    control,
    name,
    label,
    showYear,
    showSymptoms,
    showDateLast,
    showDoctor,
    showDiagnosis,
    showObservation
  } = props;
  const have = useWatch({ control, name: `${name}.have` });
  return (
    <>
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
        <div className='col-span-4'>
          <RadioGroup
            name={`${name}.have`}
            label={label}
            options={[
              {
                label: "Sim",
                value: true,
              },
              {
                label: "Não",
                value: false,
              },
            ]}
          />
        </div>
        <div className='col-span-4' style={{
            opacity: have ? 1 : 0.2,
            pointerEvents: have ? "all" : "none",
          }}>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            {showYear && (
              <div className='col-span-4'>
                <NumberInput
                  name={`${name}.year`}
                  label="Ano de diagnóstico"
                  format="####"
                // compact
                />
              </div>
            )}
            {showSymptoms && (
              <div className='col-span-4'>
                <TextInput
                  name={`${name}.symptoms`}
                  label="Sinais e sintomas"
                  placeholder="Digite aqui os sinais"
                // compact
                />
              </div>
            )}
          </div>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            {showDateLast && (
              <div className='col-span-4'>
                <TextInput
                  name={`${name}.dateLast`}
                  label="Data última"
                  type="date"
                />
              </div>
            )}
            {showDoctor && (
              <div className='col-span-4'>
                <TextInput
                  name={`${name}.doctor`}
                  label="Nome Médico"
                  placeholder="Digite o nome do médico"
                // compact
                />
              </div>
            )}
          </div>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            {showDiagnosis && (
              <div className='col-span-4'>
                <TextInput
                  name={`${name}.diagnosis`}
                  label="Diagnóstico"
                  placeholder="Digite o diagnóstico"
                // compact
                />
              </div>
            )}
          </div>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            {showObservation && (
              <div className='col-span-8'>
                <TextArea
                  name={`${name}.observation`}
                  label="Observações"
                  placeholder="Digite as observações"
                // compact
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Cabecalho: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <MedTitle>Cabeçalho</MedTitle>
      <HR />
      <Row>
        <Col>
          <TextInput name="consultationDate" label="Data da Consulta" type="date" />
        </Col>
      </Row>
    </Section>
  );
};

const ResumoDasConsultas: React.FC<MethodsProps> = (props) => {
  return (
    <Row >
      <Col>
        <Section style={{ height: "100%" }}>
          <MedTitle>Resumo das consultas</MedTitle>
          <HR />
          <TextInput name="resume" label="Resumo" />
        </Section>
      </Col>
    </Row>
  )
}

const AvaliacaoAtual: React.FC<MethodsProps> = (props) => {
  const { control } = props;
  const { isMaisMulher } = useContext(ClientContext)
  const haveThyropathy = useWatch({ control, name: "thyropathy.have" });
  const haveCardiologistAppointment = useWatch({ control, name: "cardiologistAppointment.have" });
  const useHormonalReplacement = useWatch({ control, name: "hormonalReplacement.use" });

  return (
    <>
      <Section>
        <MedTitle>Avaliação atual</MedTitle>
        <HR />

        <Collapse title="GERAL" subtitle="Avaliação atual/" color={"med-color"}>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
            <div className='col-span-8'>
              <CheckGroup
                name="mainComplaint.complaints"
                label="Queixa principal"
                options={["Obesidade", "Diabetes", "Tireoide", "Outra",]}
                renderOther={
                  <TextInput name="mainComplaint.others" label="Outras" />
                }
              />
            </div>
            <div className='col-span-8'>
              <TextArea
                name="historyComplaint"
                label="História pregressa da moléstia"
                placeholder="Digite aqui a história"
              />
            </div>

            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="headache"
                label="Cefaleia?"
                showSymptoms
              />
            </div>
            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="thyropathy"
                label="Tireopatia?"
                showYear
                showSymptoms
              />
            </div>

            <div className='col-span-8'
            style={{
              opacity: haveThyropathy ? 1 : 0.2,
              pointerEvents: haveThyropathy
                ? "all"
                : "none",
            }}>
              <CheckGroup
                name="thyropathy.complaints"
                label="Qual tireopatia?"
                options={["Hipotireoidismo", "Hipertireoidismo", "Nódulos", "Câncer", "Outras"]}
                renderOther={
                  <TextInput name="thyropathy.others" label="Outras" />
                }
              />
            </div>

            <div className='col-span-8'>
              <Diagnosis
              {...props}
              name="cardiologistAppointment"
              label="Consulta cardiologista?"
              showDateLast
              showDoctor
              showObservation
            />
            </div>

            <div className='col-span-8'
              style={{
                opacity: haveCardiologistAppointment
                  ? 1
                  : 0.2,
                pointerEvents: haveCardiologistAppointment
                  ? "all"
                  : "none",
              }}
            >
              <CheckGroup
                name="cardiologistAppointment.complaints"
                label="Queixas cardíacas"
                options={["Dor pré-cordial", "Palpitações", "Dispnéia", "Hipertensão", "Colesterol elevado", "Triglicerídeos", "Outras"]}
                renderOther={
                  <TextInput name="cardiologistAppointment.others" label="Outras" />
                }
              />
            </div>
          </div>
          </Collapse>

          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
            <div className='col-span-8'>
              <TextArea
                name="otherObservations.currentAvaliation"
                label="Observações"
              />
            </div>
          </div>
      </Section>

      <Section>
        <Collapse title={`${isMaisMulher ? '' : 'UROLOGISTA-'}GINECOLOGISTA`} subtitle="Avaliação atual/" color={"med-color"}>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
            <div className='col-span-4'>
              <CheckGroup
                name="urinarySymptoms.value"
                label="Sintomas urinários?"
                options={["Não", "Disúria", "Poliúria", "Outros"]}
                renderOther={
                  <TextInput name="urinarySymptoms.other" label="Outros" />
                }
              />
            </div>
            <div className='col-span-4'>
              <RadioGroup
                name="urinaryInfection"
                label="Infecção urinária em repetição?"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
            </div>

            <div className='col-span-8'>
              {!isMaisMulher && (<Diagnosis
                {...props}
                name="urologistAppointment"
                label="Consulta urologista?"
                showDateLast
                showDoctor
                showObservation
              />)}
            </div>

            {!isMaisMulher && (
              <div className='col-span-8'>
                <RadioGroup
                  name="sexualPotency"
                  label="Potência sexual"
                  options={["Normal", "Diminuída"]}
                />
              </div>)
            }

            <div className='col-span-8'>
              <RadioGroup
                name="libido"
                label="Libido"
                options={["Normal", "Diminuída"]}
              />
            </div>
            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="gynecologistAppointment"
                label="Consulta ginecologista?"
                showDateLast
                showDoctor
                showObservation
              />
            </div>
            <div className='col-span-4'>
              <NumberInput name="menarche" label="Menarca" format="## anos" />
            </div>
            <div className='col-span-4'>
              <NumberInput name="menopause" label="Menopausa" format="## anos" />
            </div>
            <div className='col-span-2'>
              <NumberInput name="pregnancies" label="Gestações" format="##" />
            </div>
            <div className='col-span-3'>
              <NumberInput name="abortions" label="Abortos" format="##" />
            </div>
            <div className='col-span-3'>
              <NumberInput name="childBirths" label="Partos" format="##" />
            </div>

            <div className='col-span-8'>

            </div>
            <div className='col-span-8'>
              <CheckGroup
                name="menstrualCycles.value"
                label="Ciclos menstruais"
                options={["Normal", "Amenorreia", "Oligomenorreia", "Hipomenorreia", "Menorragia", "Metrorragia", "Espaniomenorreia", "Outras"]}
                renderOther={
                  <TextInput name="menstrualCycles.other" label="Outras" />
                }
              />
            </div>
            <div className='col-span-8'>
              <RadioGroup
                name="macrosomalFetuses"
                label="Fetos macrossômicos?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className='col-span-8'>
              <CheckGroup
                name="contraceptive.value"
                label="Método anticoncepcional"
                options={[
                  "Anticoncepcional", "DIU", "Laqueadura", "Vasectomia", "Tabela", "Coito interrompido", "Implante", "Outro",
                ]}
                renderOther={
                  <TextInput name="contraceptive.other" label="Outro" />
                }
              />
            </div>

            <div className='col-span-4'>
              <RadioGroup
                name="hormonalReplacement.use"
                label="Reposição hormonal?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className='col-span-4'
              style={{
                opacity: useHormonalReplacement ? 1 : 0.2,
                pointerEvents: useHormonalReplacement
                  ? "all"
                  : "none",
              }}>
              <TextArea
                name="hormonalReplacement.time"
                label="Tempo de uso"
                placeholder="Descreva aqui o tempo de uso"
              // compact
              />
            </div>
            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="vulvovaginitisBalanoposthitis"
                label="Vulvovaginite/Balanopostite?"
                showDiagnosis
                showObservation
              />
            </div>
            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="acne"
                label="Acne?"
                showDiagnosis
                showObservation
              />
            </div>
            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="hirsutism"
                label="Hirsutismo?"
                showDiagnosis
                showObservation
              />
            </div>
            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="lossOfHair"
                label="Queda de cabelo?"
                showDiagnosis
                showObservation
              />
            </div>
            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="hotFlushes"
                label="Fogachos?"
                showDiagnosis
                showObservation
              />
            </div>
            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="mammaryComplaints"
                label="Queixas mamárias?"
                showObservation
              />
            </div>
            <div className='col-span-8'>
              <Diagnosis
                {...props}
                name="preventiveExams"
                label={`Exames preventivos ${!isMaisMulher ? 'urológicos/' : ''}ginecológicos?`}
                showDiagnosis
                showDateLast
              />
            </div>
            <div className='col-span-8'>
              <TextInput
                name="generalSymptoms"
                label="Outros sintomas sistêmicos"
                placeholder="Fraqueza/astenia/fadiga crônica/edema…"
              />
            </div>
          </div>
        </Collapse>

        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
          <div className='col-span-8'>
            <TextArea
              name="otherObservations.urologystAndGynecologist"
              label="Observações"
            />
          </div>
        </div>
      </Section>

      <Section>
        <Collapse title="GASTRO-INTESTINAL" subtitle="Avaliação atual/" color={"med-color"}>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
            <div className='col-span-4'>
              <CheckGroup
                name="digestionAndFoodFunction.symptoms"
                label="Queixas gastroesofágicas?"
                options={[
                  "Não",
                  "Refluxo",
                  "Gastrite",
                  "Náuseas",
                  "Vômitos",
                  "Outras",
                ]}
                renderOther={
                  <TextInput name="digestionAndFoodFunction.others" label="Outras" />
                }
              />
            </div>
            <div className='col-span-4'>
              <TextInput
                name="digestionAndFoodFunction.observations"
                label="Observações sobre as queixas gastroesofágicas?"
                placeholder="Digite aqui as observações"
              />
            </div>

            <div className='col-span-4'>
              <RadioGroup
                name="digestionAndFoodFunction.intestinalFunction"
                label="Como é sua função intestinal?"
                options={["Constipado", "Normal", "Diarreia"]}
              />
            </div>
            <div className='col-span-4'>
              <TextInput
                name="digestionAndFoodFunction.intestinalFunctionObservations"
                label="Observações sobre a função intestinal"
                placeholder="Digite aqui as observações"
              />
            </div>
            <div className='col-span-8'>
              <NumberInput
                name="digestionAndFoodFunction.evaluationNumber"
                label="Quantas evacuações na semana"
                suffix=" evacuações semanais"
              />
            </div>

          <div className='col-span-8'>
            <Diagnosis
              {...props}
              name="endoscopy"
              label="Endoscopia?"
              showDateLast
              showDiagnosis
              showObservation
            />
          </div>
          <div className='col-span-8'>
            <Diagnosis
              {...props}
              name="colonoscopy"
              label="Colonoscopia?"
              showDateLast
              showDiagnosis
              showObservation
            />
          </div>
        </div>
        </Collapse>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
          <div className='col-span-8'>
            <TextArea
              name="otherObservations.digestionAndFoodFunction"
              label="Observações"
            />
          </div>
        </div>
      </Section>


    </>
  );
};

const DoencasAssociadas: React.FC<MethodsProps> = (props) => {
  const { control } = props;
  const polysomnography = useWatch({ control, name: "rest.polysomnographyDate" });

  return (
    <>
      <Section>
        <MedTitle>Doenças associadas</MedTitle>
        <HR />

        <Collapse title="PSICOLOGIA/PSIQUIATRIA" subtitle="Doenças associadas/" color={"med-color"}>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
            <div className='col-span-8'>
              <CheckGroup
                name="psychology.values"
                label=""
                options={[
                  "Depressão",
                  "Ansiedade",
                  "Déficit de atenção",
                  "Bipolaridade",
                  "Outras",
                ]}
                renderOther={
                  <TextInput name="psychology.other" label="Outras psicopatias" />
                }
              />
            </div>
            <div className='col-span-4'>
              <RadioGroup
                name="psychology.currentTreatment.do"
                label="Tratamento atual"
                 options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className='col-span-4'>
              <TextInput
                name="psychology.currentTreatment.psychologist"
                label="Nome Psicólogo"
                placeholder="Digite aqui o nome"
              // compact
              />
              <TextInput
                name="psychology.currentTreatment.psychiatrist"
                label="Nome Psiquiatra"
                placeholder="Digite aqui o nome"
              // compact
              />
            </div>
          </div>
        </Collapse>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
            <div className='col-span-8'>
            <TextArea
              name="otherObservations.psicology"
              label="Observações"
            />
          </div>
        </div>
      </Section>

      <Section>
        <Collapse title="SONO" subtitle="Doenças associadas/" color={"med-color"}>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
            <div className='col-span-8'>
              <NumberInput
                label="Horas de Sono"
                name="rest.sleepHours"
                format="## horas"
              />
            </div>
            <div className='col-span-4'>
              <RadioGroup
                name="rest.snore"
                label="Ronca?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className='col-span-4'>
              <RadioGroup
                name="rest.apnea"
                label="Apneia?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>

            <div className='col-span-4'>
              <RadioGroup
                name="rest.polysomnography"
                label="Fez polissonografia?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className='col-span-4'
               style={{
                opacity: polysomnography ? 1 : 0.2,
                pointerEvents: polysomnography
                  ? "all"
                  : "none",
              }}
            >
              <TextInput
                name="rest.polysomnographyDate"
                label="Data da última"
                type="date"
              />
              <TextInput
                name="rest.polysomnographyObservation"
                label="Obeservações sobre a polissonografia"
              // compact
              />
            </div>
          </div>
        </Collapse>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
          <div className='col-span-8'>
            <TextArea
              name="otherObservations.rest"
              label="Observações"
            />
          </div>
        </div>
      </Section>

      <Section>
        <Collapse title="SINTOMAS MÚSCULO-ESQUELÉTICOS" subtitle="Doenças associadas/" color={"med-color"}>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
          <div className='col-span-8'>
            <Diagnosis
              {...props}
              name="jointSymptoms"
              label="Sintomas articulares/musculares?"
              showObservation
            />
          </div>
          <div className='col-span-8'>
            <Diagnosis
              {...props}
              name="ostrearthrosis"
              label="Osteoartrose?"
              showObservation
            />
          </div>
          <div className='col-span-8'>
            <Diagnosis
              {...props}
              name="otherRheumatologicalDiseases"
              label="Outras doenças reumatológicas?"
              showObservation
            />
          </div>
        </div>    
        </Collapse>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
          <div className='col-span-8'>
            <TextArea
              name="otherObservations.muscles"
              label="Observações"
            />
          </div>
        </div>
      </Section>

      <Section>
        <Collapse title="DOENÇAS HEPÁTICAS" subtitle="Doenças associadas/" color={"med-color"}>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
          <div className='col-span-4'>
            <RadioGroup
              name="hepaticDisease.steatosis"
              label="Esteatose?"
              multiple={false}
              options={["Não", "Grau I", "Grau II", "Grau III", "Grau não referido"]}
            />
          </div>
          <div className='col-span-4'>
            <RadioGroup
              name="hepaticDisease.cholelithiasis"
              label="Colelitíase?"
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
            />
          </div>
          
          <div className='col-span-8'>
              <RadioGroup
                name="hepaticDisease.viralHepatitis"
                label="Hepatite viral?"
                options={["A", "B", "C", "Outra"]}
                renderOther={
                  <TextInput name="hepaticDisease.otherViralHepatitis" label="Outra" />
                }
              />
          </div>
        </div>
        </Collapse>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
          <div className='col-span-8'>
            <TextArea
              name="otherObservations.hepaticDisease"
              label="Observações"
            />
          </div>
        </div>
      </Section>


      <Section>
        <Collapse title="DOENÇAS CARDIOVASCULARES" subtitle="Doenças associadas/" color={"med-color"}>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
            <div className='col-span-4'>
              <RadioGroup
                name="cardiovasculaDisease.arterialHypertension"
                label="Hipertensão arterial?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className='col-span-4'>
              <RadioGroup
                name="cardiovasculaDisease.ischemicHeartDisease"
                label="Cardiopatia isquêmica?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>

            <div className='col-span-4'>
              <RadioGroup
                name="cardiovasculaDisease.arterialDisease"
                label="Doença arterial periférica, carotídea e cerebral?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className='col-span-4'>
              <RadioGroup
                name="cardiovasculaDisease.hypercholesterolemia"
                label="Hipercolesterolemia?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>

            <div className='col-span-4'>
              <RadioGroup
                name="cardiovasculaDisease.hypertriglyceridemia"
                label="Hipertrigliceridemia?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className='col-span-4'>
              <RadioGroup
                name="cardiovasculaDisease.lowHDL"
                label="HDL baixo?"
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
          </div>
        </Collapse>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
            <div className='col-span-8'>
            <TextArea
              name="otherObservations.cardiovasculaDisease"
              label="Observações"
            />
          </div>
        </div>
      </Section>
    </>
  );
};

const AntecedentesEHabitos: React.FC<MethodsProps> = (props) => {
  const { control } = props;
  const useCigarette = useWatch({ control, name: "habits.cigarette.use" });
  const useAlcohol = useWatch({ control, name: "habits.alcohol.use" });
  const useDrugs = useWatch({ control, name: "habits.drugs.use" });
  const doneSurgery = useWatch({ control, name: "habits.surgery.done" });

  return (
    <Section>
      <MedTitle>Antecedentes e Hábitos</MedTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
      <div className='col-span-8'>
        <TextArea
            name="habits.previousDiseases"
            label="Doenças anteriores"
            placeholder="Digite aqui"
        />
      </div>

      <div className='col-span-4'>
          <RadioGroup
            name="habits.cigarette.use"
            label="Cigarros?"
            options={[
              {
                label: "Sim",
                value: true,
              },
              {
                label: "Não",
                value: false,
              },
            ]}
          />
        </div>
        <div className='col-span-4'
          style={{
            opacity: useCigarette ? 1 : 0.2,
            pointerEvents: useCigarette
              ? "all"
              : "none",
          }}
        >
          <NumberInput
              name="habits.cigarette.number"
              label="Número por dia"
              format="## cigarros por dia"
            // compact
            />
          <TextInput
                name="habits.cigarette.usageTime"
                label="Tempo de uso"
                placeholder="Digite aqui"
              // compact
            />
           <TextInput
                name="habits.cigarette.abandonTime"
                label="Tempo abandono"
                placeholder="Digite aqui"
              // compact
              />
        </div>

      <div className='col-span-4'>
        <RadioGroup
          name="habits.alcohol.use"
          label="Álcool?"
          options={[
            {
              label: "Sim",
              value: true,
            },
            {
              label: "Não",
              value: false,
            },
          ]}
        />
      </div>
      <div className='col-span-4' 
        style={{
            opacity: useAlcohol ? 1 : 0.2,
            pointerEvents: useAlcohol
              ? "all"
              : "none",
          }}>
          <TextInput
            name="habits.alcohol.typeOf"
            label="Tipo de bebida consumida"
            placeholder="Digite aqui"
          />
          <NumberInput
            name="habits.alcohol.amount"
            label="Quantidade (ml/dia)"
            format="#### ml por dia"
          />
          <NumberInput
              name="habits.alcohol.frequency"
              label="Vezes por semana"
              format="#### vezes"
            />
      </div>

      <div className='col-span-4'>
        <RadioGroup
          name="habits.drugs.use"
          label="Drogas?"
          options={[
            {
              label: "Sim",
              value: true,
            },
            {
              label: "Não",
              value: false,
            },
          ]}
        />
      </div>
      <div className='col-span-4'
        style={{
          opacity: useDrugs ? 1 : 0.2,
          pointerEvents: useDrugs
            ? "all"
            : "none",
        }}
      >
        <TextInput
          name="habits.drugs.typeOf"
          label="Tipo"
          placeholder="Digite aqui"
        // compact
        />
        <TextInput
          name="habits.drugs.amount"
          label="Dose"
          placeholder="Digite aqui"
        // compact
        />
        <TextInput
          name="habits.drugs.frequency"
          label="Frequência"
          placeholder="Digite aqui"
        // compact
        />
        <TextInput
          name="habits.drugs.usageTime"
          label="Tempo de uso"
          placeholder="Digite aqui"
        // compact
        />
        <TextInput
          name="habits.drugs.abandonTime"
          label="Tempo abandono"
          placeholder="Digite aqui"
        // compact
        />
      </div>

      <div className='col-span-4'>
        <RadioGroup
          name="habits.surgery.done"
          label="Cirurgias?"
          options={[
            {
              label: "Sim",
              value: true,
            },
            {
              label: "Não",
              value: false,
            },
          ]}
        />
      </div>
      <div className='col-span-4'
        style={{
          opacity: doneSurgery ? 1 : 0.2,
          pointerEvents: doneSurgery
            ? "all"
            : "none",
        }}
      >
        <TextInput
            name="habits.surgery.typeOf"
            label="Tipo"
            placeholder="Digite aqui"
          // compact
          />
        <TextInput
              name="habits.surgery.time"
              label="Tempo de realização"
              placeholder="Digite aqui"
            // compact
            />
      </div>

      <div className='col-span-8'>
          <TextArea
            name="habits.other"
            label="Outros antecedentes"
            placeholder="Digite aqui"
          />
      </div>
      <div className='col-span-8'>
          <TextArea
            name="otherObservations.habits"
            label="Observações"
          />
      </div>
      </div>
    </Section>
  );
};

const OutrasDoencas: React.FC<MethodsProps> = ({ control }) => {

  const medicines = useFieldArray({ control, name: "medicines" })

  return (
    <>
      <Section>
        <MedTitle>Outras doenças associadas</MedTitle>
        <HR />

        <Collapse title="COMPLICAÇÕES CRÔNICAS DO DIABETES" subtitle="Outras doenças associadas/" color={"med-color"}>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className='col-span-8'>
            <Deseases
                name="otherAssociatedDiseases"
                label=""
                options={["Pé diabético", "Oftalmopatia", "Cardiovascular", "Nefropatia", "Neuropatia periférica"]}
              />
            </div>
          </div>
          <div className='col-span-8'>
            <Col>
              <TextArea
                name="otherComplications"
                label="Outras complicações"
                placeholder="Digite aqui"
              />
            </Col>
          </div>
        </Collapse>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className='col-span-8'>
            <TextArea
              name="otherObservations.otherAssociatedDiseases"
              label="Observações"
            />
          </div>
        </div>
      </Section>


      <Section>
        <Collapse title="MEDICAMENTOS" subtitle="Outras doenças associadas/" color={"med-color"}>
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            <div className='col-span-8'>
              {medicines.fields.length === 0 && (
                <p className="text-gray-400 font-bold px-2 py-8">Nenhum medicamento adicionado</p>
              )}

              <div className="flex flex-wrap">
                {medicines.fields.map((field, index) => (
                  <MedicineForm
                    key={field.id}
                    name={`medicines.${index}`}
                    onRemove={() => medicines.remove(index)}
                  />
                ))}
              </div>

              <button
                type="button"
                className="flex outline-none border font-medium rounded-lg text-sm text-center mr-2 mb-2 h-fit text-white bg-white hover:bg-purple-100 text-purple-700 hover:text-purple-800 px-2.5 py-2"
                onClick={() => {
                  medicines.append({ })
                }}
              >
                  + Adicionar outro medicamento
              </button>
            </div>
            <div className='col-span-8'>
              <TextArea
                name="diabetesAndObesityMedicines"
                label="Medicações já utilizadas para diabetes e obesidade"
                placeholder="Digite aqui"
              />
            </div>
          </div>
        </Collapse>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            <div className='col-span-8'>
              <TextArea
                name="otherObservations.medicines"
                label="Observações"
              />
            </div>
        </div>
      </Section>
    </>
  );
};

const AntecedentesFamiliares: React.FC<MethodsProps> = (props) => {
  const { control } = props;
  const data = useWatch({ control });

  return (
    <Section>
      <MedTitle>Antecedentes Familiares</MedTitle>
      <HR />

      <Collapse title="GERAL" subtitle="Antecedentes Familiares/" color={"med-color"}>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
            
        {[
          {
            label: "Diabetes?",
            id: "familyBackground.diabetes.have",
            memberId: "familyBackground.diabetes.whichFamilyMember",
          },
          {
            label: "Hipertensão arterial?",
            id: "familyBackground.arterialHypertension.have",
            memberId: "familyBackground.arterialHypertension.whichFamilyMember",
          },
          {
            label: "Obesidade?",
            id: "familyBackground.obesity.have",
            memberId: "familyBackground.obesity.whichFamilyMember",
          },
          {
            label: "Cardiopatia isquêmica?",
            id: "familyBackground.ischemicHeartDisease.have",
            memberId: "familyBackground.ischemicHeartDisease.whichFamilyMember",
          },
          {
            label: "AVC?",
            id: "familyBackground.stroke.have",
            memberId: "familyBackground.stroke.whichFamilyMember",
          },
          {
            label: "Tromboembolismo?",
            id: "familyBackground.thromboembolism.have",
            memberId: "familyBackground.thromboembolism.whichFamilyMember",
          },
          {
            label: "Hemocromatose?",
            id: "familyBackground.hemochromatosis.have",
            memberId: "familyBackground.hemochromatosis.whichFamilyMember",
          },
          {
            label: "Câncer?",
            id: "familyBackground.cancer.have",
            memberId: "familyBackground.cancer.whichFamilyMember",
          },
          {
            label: "Outra?",
            id: "familyBackground.other.have",
            memberId: "familyBackground.other.whichFamilyMember",
            morbidityId: "familyBackground.other.morbidity",
          },
        ].map((option) => (
          <React.Fragment key={option.id}>
            <div className='col-span-4'>
              <RadioGroup
                name={option.id}
                label={option.label}
                options={[
                  {
                    label: "Sim",
                    value: true,
                  },
                  {
                    label: "Não",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className='col-span-4'
              style={{
                opacity: _.get(data, option.id) ? 1 : 0.2,
                pointerEvents: _.get(data, option.id) ? "all" : "none",
              }}
            >
              {option.morbidityId && (
                <TextInput
                  name={option.morbidityId}
                  placeholder="Digite aqui..."
                  label="Qual morbidade?"
                />
              )}
              <TextInput
                name={option.memberId}
                placeholder="Digite aqui..."
                label="Qual familiar?"
              />
            </div>
          </React.Fragment>
        ))}
        </div>
      </Collapse>
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
        <div className='col-span-8'>
          <TextArea
            name="otherObservations.family"
            label="Observações"
          />
        </div>
      </div>
    </Section>
  );
};

const SolicitacaoExames: React.FC<MethodsProps> = (props) => {
  const { clientId } = useParams<ClientParams>();
  const user = useSelector(selectUser);

  const downloadExamsRequest = useCallback(async (exams) => {
    try {
      const doctor = user.name;
      const crm = user.crm;
      const response = await PDFService.examsRequest(clientId, exams, doctor, crm);
      //@ts-ignore
      window.open(URL.createObjectURL(response.data));
    } catch (e) {}
  }, [clientId, user]);
  
  return (
    <Section>
      <MedTitle>Solicitação de exames</MedTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
        <div className='col-span-8'>
          <ExamsRequest 
            name="examRequest" 
            downloadExamsRequest={downloadExamsRequest}
            />
        </div>
        <div className='col-span-8'>
          <TextArea
            name="otherObservations.examsRequest"
            label="Observações"
          />
        </div>
      </div>
    </Section>
  );
};

const ExameFisico: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <MedTitle>Exames</MedTitle>
      <HR />
      <Collapse title="Físicos" subtitle="Exames" color={"med-color"}>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
          <div className='col-span-4'>
            <TextArea
              name="physicalExams.acanthosisNigricans"
              label="Acantose nigricans"
              placeholder="Digite aqui"
            />
          </div>
          <div className='col-span-4'>
            <TextArea
              name="physicalExams.hirsutism"
              label="Hirsutismo"
              placeholder="Digite aqui"
            />
          </div>
          <div className='col-span-4'>
            <TextArea
              name="physicalExams.thyroidPalpation"
              label="Palpação da tireóide"
              placeholder="Digite aqui"
            />
          </div>
          <div className='col-span-4'>
            <TextArea
              name="physicalExams.abdominalPalpation"
              label="Palpação do abdomem"
              placeholder="Digite aqui"
            />
          </div>
          <div className='col-span-4'>
            <TextArea
              name="physicalExams.lowerMembers"
              label="Membros inferiores"
              placeholder="Digite aqui"
            />
          </div>
          <div className='col-span-4'>
            <TextArea
              name="physicalExams.otherPositiveTestData"
              label="Outros dados positivos do exame"
              placeholder="Digite aqui"
            />
          </div>
        </div>
      </Collapse>
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
        <div className='col-span-8'>
          <TextArea
            name="otherObservations.physicalExams"
            label="Observações"
          />
        </div>
      </div>
    </Section>
  );
};

const ExameComplementares: React.FC<MethodsProps> = (props) => {
  const name = "files"
  const { control, setValue } = props;
  const filesRef = useRef({});
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    const filesToUpload = acceptedFiles.filter((file) => {
      return filesRef.current[file.path] === undefined;
    });

    filesToUpload.forEach(async (file) => {
      filesRef.current[file.path] = true;
      setValue(
        name,
        Object.keys(filesRef.current).map((key) => filesRef.current[key]),
        { shouldTouch: true }
      );
      //@ts-ignore
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await FileService.uploadImage(formData);
        const link = response.data.data.file.url;
        filesRef.current[file.path] = link;
        setValue(
          name,
          Object.keys(filesRef.current).map((key) => filesRef.current[key]),
          { shouldTouch: true }
        );
      } catch (e) { }
    });
  }, [acceptedFiles, setValue]);

  return (
    <Section>
      <Collapse title="Laboratório" subtitle="Exames complementares/" color={"med-color"}>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
          {LABORATORY_EXAMS.map((exam) => (
            <div key={exam.name} className='col-span-8'>
              <Exam
                //@ts-ignore
                label={exam.label}
                //@ts-ignore
                name={`exams.${exam.name}`}
                //@ts-ignore
                suffix={exam.suffix}
                //@ts-ignore
                references={exam.references}
              />
            </div>
          ))}
        </div>
      </Collapse>

      <Collapse title="Cardiológicos" subtitle="Exames complementares/" color={"med-color"}>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
          {CARDIOLOGY_EXAMS.map((exam) => (
            <div key={exam.name} className='col-span-8'>
              <Exam
                label={exam.label}
                name={`exams.${exam.name}`}
                suffix={exam.suffix}
                references={exam.references}
              />
            </div>
          ))}
        </div>
      </Collapse>

      <Collapse title="Radiologia" subtitle="Exames complementares/" color={"med-color"}>
        <section className="bg-gray-300 text-white p-8 center m-4 rounded-lg">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Clique ou arraste aqui os arquivos</p>
          </div>
        </section>
        <aside style={{ padding: 17, fontSize: 14 }}>
          <h5>Arquivos</h5>
          <Controller
            render={({ field }) => {
              const files = field.value || [];
              return (
                <ul>
                  {(files || []).map((url, index) => (
                    <li key={`${index - url}`}>
                      {url === true ? (
                        <span key={index} style={{ marginRight: 15 }}>
                          Fazendo upload...
                        </span>
                      ) : (
                        <>
                          <a href={url} key={url} style={{ marginRight: 15 }}>
                            Arquivo {url}
                          </a>
                          <button
                            type="button"
                            onClick={() => {
                              setValue(
                                name,
                                (files || []).filter((u) => u !== url),
                                { shouldTouch: true }
                              );
                            }}
                          >
                            Excluir
                          </button>
                        </>
                      )}
                    </li>
                  ))}
                </ul>)
            }}
            control={control}
            name={name}
          />
        </aside>
      </Collapse>

      <Collapse title="Outros exames" subtitle="Exames complementares/" color={"med-color"}>
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
          <div className='col-span-8'>
            <TextArea
              name="exams.others"
              label="Outros exames"
            />
          </div>
        </div>
      </Collapse>

      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
          <div className='col-span-8'>
            <TextArea
              name="otherObservations.otherExams"
              label="Observações"
            />
          </div>
        </div>
    </Section>
  );
};

const Diagnosticos: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <MedTitle>Diagnósticos</MedTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
        <div className='col-span-8'>
          <TextArea
            name="medicalDiagnoses"
            label="Diagnósticos médicos"
            placeholder="Digite aqui"
          />
        </div>
        <div className='col-span-8'>
          <TextArea
            name="hauxDiagnoses"
            label="Diagnóstico para a equipe"
            placeholder="Digite aqui"
          />
        </div>
        <div className='col-span-8'>
          <TextArea
            name="otherObservations.diagnoses"
            label="Observações para a equipe Haux"
          />
        </div>
      </div>
    </Section>
  );
};

const Condutas: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <MedTitle>Conduta</MedTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
        <div className='col-span-8'>
          <TextArea
            name="conduct"
            label="Condutas"
            placeholder="Digite aqui"
          />
        </div>
        <div className='col-span-8'>
          <TextInput name="returnDate" label="Previsão de retorno" type="date" />
        </div>
        <div className='col-span-8'>
          <TextArea
            name="otherObservations.conduct"
            label="Observações"
          />
        </div>
      </div>
    </Section>
  );
};

const TemplateQuestions: React.FC<MethodsProps> = (props) => {
  const { control } = props;
  const questionSections = useFieldArray({ control, name: "questionSections" })

  return (
  <>
    {questionSections.fields.map((section, sectionIndex) => (
    <Section key={section.id}>
      <MedTitle>{_.get(section, ["title"])}</MedTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 p-4">
        {(_.get(section, ["questionsAndAnswers"]) ?? []).map((qa, questionIndex) => (
          <div className='col-span-8' key={qa.id}>
            <TextArea
              name={`questionSections.${sectionIndex}.questionsAndAnswers.${questionIndex}.answer`}
              label={_.get(section, ["questionsAndAnswers", questionIndex, "question"])}
              placeholder="Digite aqui"
            />
          </div>
        ))}
      </div>
    </Section>
  ))}
  </>)

}

interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  anamnesisId?: string;
  meetingNumber?: string;
}

const Anamnesis: React.FC = () => {
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { reset, control, handleSubmit, formState } = methods;
  const data = useWatch({ control });
  const finished = useWatch({ control, name: "finish" });
  const history = useHistory();
  const { clientId, anamnesisId, meetingNumber } = useParams<ClientParams>();

  const hasTemplate: boolean = data && data.questionSections && data.questionSections.length > 0;

  const getAnamnesisData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await AnamnesisService.getAllAnamnesis("med", clientId);
      const item = _.find(_.get(response, ["data", "data", "items"], []), [
        "_id",
        anamnesisId,
      ]);
      if (item) {
        if (!item.resume) {
          const items = ["currentAvaliation", "urologystAndGynecologist", "digestionAndFoodFunction", "psicology", "rest", "muscles", "hepaticDisease", "cardiovasculaDisease", "habits", "otherAssociatedDiseases", "medicines", "family", "examsRequest", "physicalExams", "otherExams", "diagnoses", "conduct"];
          item.resume = ""
          items.forEach((i) => {
            const d = _.get(item, ["otherObservations", i]);
            if (d) {
              item.resume = `${item.resume}<div>${d}</div><br/>`
            }

          })
        }
        reset({ ...item, client: clientId });
      } else {
        throw new Error();
      }
    } catch (e) {
      NotificationManager.error("Erro ao obter dados da anamnese");
      history.push(`/client/${clientId}/med`);
    }
    setLoading(false);
  }, [clientId, anamnesisId, reset, setLoading, history]);

  useEffect(() => {
    getAnamnesisData();
  }, [getAnamnesisData]);

  const getDataTransform = (data, finished = false) => {
    const form = data;

    if (finished) {
      form.finish = true;
    }

    return form;
  };

  const onSave = useCallback(
    async (data, published = false) => {
      setSaving(true);
      try {
        const values = getDataTransform(data, published);
        await AnamnesisService.insertAnamnesis("med", values);
        NotificationManager.success(
          `Ficha ${published ? "salva" : "publicada"} com sucesso`
        );
      } catch (e) {
        NotificationManager.error("Erro ao salvar ficha");
      }
      setSaving(false);
    },
    [setSaving]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await onSave(data, true);
        getAnamnesisData();
      } catch (errors) {
        //@ts-ignore
        console.log(errors.inner.reduce(
          (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? "validation",
              message: currentError.message
            }
          }),
          {}
        )
        );
        NotificationManager.error(
          "Preencha todos os campos obrigatórios para publicar"
        );
      }
    },
    [onSave, getAnamnesisData]
  );

  if (loading) {
    return <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <LoadingOrange style={{ height: 100 }} />
    </div>;
  }

  return (
    <FormProvider {...methods}>
      <form style={{ paddingBottom: 50 }} onSubmit={handleSubmit(onSubmit)}>
        {anamnesisId && (
          <>
            <Row style={{ marginRight: 0 }}>
              <Col>
                <Cabecalho {...methods} />
                {hasTemplate && (<TemplateQuestions {...methods}/>)}
                {!hasTemplate && (
                  <>
                    {meetingNumber !== "1" && ( <ResumoDasConsultas {...methods} /> )}
                    <AvaliacaoAtual {...methods} />
                    <AntecedentesEHabitos {...methods} />
                    <DoencasAssociadas {...methods} />
                    <OutrasDoencas {...methods} />
                    <AntecedentesFamiliares {...methods} />
                    <SolicitacaoExames {...methods} />
                    <ExameFisico {...methods} />
                    <ExameComplementares {...methods} />
                  </>
                )}
                
                <Diagnosticos {...methods} />
                <Condutas {...methods} />
              </Col>
            </Row>
          </>
        )}
        <SaveBottom
          saving={saving}
          touched={formState.isDirty || !finished}
          showPublish={!finished}
          color={"tech"}
          onSave={() => {
            onSave(data, data.finish);
          }}
          published={finished}
        />
      </form>
    </FormProvider>
  );
};


export { MedTemplate };
export default Anamnesis;
