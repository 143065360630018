import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import FranchiseService from "services/FranchiseService";
import { selectUser } from "redux/ducks/Auth/selectors";
import { useSelector } from "react-redux";
import PermissionService, { IPermission } from "services/PermissionService";

export interface Franchise {
  _id: string;
  name: string;
}

type UserProviderState = {
  franchises: Franchise[];
  permissions: IPermission[];
  slugs: string[];
};

const initialState = {
  franchises: [],
  permissions: [],
  slugs: [],
};

let stateStorageKey = "UserProviderState";

function getInitialUserProviderState(): UserProviderState {
  const state = localStorage.getItem(stateStorageKey);
  return state ? JSON.parse(state) : initialState;
}

const UserProviderContext = React.createContext<UserProviderState>(
  getInitialUserProviderState()
);

type UserProviderProps = {
  children: React.ReactNode;
  type: string;
};

export function UserProvider({ children, ...props }: UserProviderProps) {
  const user = useSelector(selectUser);
  const [franchises, setFranchises] = useState<Franchise[]>(
    initialState.franchises
  );
  const [permissions, setPermissions] = useState<IPermission[]>(
    initialState.permissions
  );

  const slugs = useMemo(() => permissions.map((p) => p.slug), [permissions]);

  // useEffect(() => {
  //   localStorage.setItem(
  //     stateStorageKey,
  //     JSON.stringify({
  //       franchises,
  //       permissions,
  //     })
  //   );
  // }, [franchises, permissions]);

  const getFranchises = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const response = await FranchiseService.getFranchises();
      var data = response.data.data;
      data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setFranchises(data);
    } catch {}
  }, [user, setFranchises]);

  const getUserPermissions = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const response = await PermissionService.getMine();
      var data = response.items;
      setPermissions(data);
    } catch {}
  }, [user, setPermissions]);

  useEffect(() => {
    getFranchises();
    getUserPermissions();
  }, [getFranchises, getUserPermissions]);

  const value = {
    franchises,
    permissions,
    slugs,
  };

  return (
    <UserProviderContext.Provider {...props} value={value}>
      {children}
    </UserProviderContext.Provider>
  );
}

export const useUser = () => {
  const context = React.useContext(UserProviderContext);
  return context;
};
